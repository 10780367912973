import angular from 'angular';

import ngeoToolActivate from 'ngeo/misc/ToolActivate.js';
import ngeoToolActivateMgr from 'ngeo/misc/ToolActivateMgr.js';
import olSourceVector from 'ol/source/Vector.js';
import olLayerVector from 'ol/layer/Vector.js';
import olFeature from 'ol/Feature.js';
import * as olEvents from 'ol/events.js';
import {unByKey} from 'ol/Observable.js';
import olStyleStyle from 'ol/style/Style.js';
import olStyleFill from 'ol/style/Fill.js';
import olStyleStroke from 'ol/style/Stroke.js';
import olStyleText from 'ol/style/Text.js';
import olGeomPoint from 'ol/geom/Point.js';
import olGeomLineString from 'ol/geom/LineString.js';

const exports = angular.module('regulargrid', [ngeoToolActivateMgr.name]);

exports.Component = {
  controller: 'sitjRegulargridController',
  bindings: {
    'map': '<', // one way Angular binding !
    'active': '=',
  },
  templateUrl: () => 'sitj/regulargrid',
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitj/regulargrid', require('./regulargrid.html'));
  }
);

exports.component('sitjRegulargrid', exports.Component);

/**
 * @param {!angular.Scope} $scope Angular scope.
 * @param {ngeo.ToolActivateMgr} ngeoToolActivateMgr Ngeo ToolActivate manager.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($scope, ngeoToolActivateMgr) {
  /**
   * @type {number}
   * @export
   */
  this.square_size = 50;

  /**
   * @type {number}
   * @export
   */
  this.grid_width = 4;

  /**
   * @type {number}
   * @export
   */
  this.grid_height = 4;

  /**
   * @type {boolean}
   * @export
   */
  this.show_center_coords = true;

  /**
   * @type {boolean}
   * @export
   */
  this.show_grid_size = true;

  /**
   * @type {ol.Map}
   * @export
   */
  this.map;
  /**
   * @type {boolean}
   */
  this.active;

  /**
   * @type {ngeo.ToolActivateMgr}
   * @private
   */
  this.ngeoToolActivateMgr_ = ngeoToolActivateMgr;

  /**
   * @type {ol.source.Vector}
   */
  this.source = new olSourceVector();

  /**
   * @type {ol.Feature}
   */
  this.centralFeature = new olFeature();

  /**
   * @type {ol.Feature}
   */
  this.gridSizeFeature = new olFeature();

  /**
   * @type {ol.layer.Vector}
   */
  this.vectorLayer_;

  /**
   * @type {ol.EventsKey}
   * @private
   */
  this.regulargridClickListenerKey_;

  // Initialize the tools inside of the tool manager
  this.tool = new ngeoToolActivate(this, 'active');
  this.ngeoToolActivateMgr_.registerTool('mapTools', this.tool, false);

  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.active;
    }.bind(this),
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateEventsListening_(newValue);
      }
    }.bind(this)
  );
};

exports.Controller.prototype.$onInit = function () {
  const styleFunction = (feature, resolution) => {
    const geom_type = feature.getGeometry().getType();
    if (geom_type == 'Point') {
      return new olStyleStyle({
        text: new olStyleText({
          font: '12px Arial,Helvetica,sans-serif',
          fill: new olStyleFill({color: feature.getProperties().color}),
          stroke: new olStyleStroke({
            color: '#fff',
            width: 2,
          }),
          text: feature.getProperties().text,
        }),
      });
    } else if (geom_type == 'LineString') {
      return new olStyleStyle({
        stroke: new olStyleStroke({
          color: [255, 0, 0, 0.8],
          width: 2,
        }),
      });
    }
  };

  this.vectorLayer_ = new olLayerVector({
    source: this.source,
    style: styleFunction,
  });
  this.vectorLayer_.setMap(this.map);
};

/**
 * @param {boolean} activate Activation state of the plugin
 * @private
 */
exports.Controller.prototype.updateEventsListening_ = function (activate) {
  if (activate === true) {
    this.ngeoToolActivateMgr_.activateTool(this.tool);
    this.regulargridClickListenerKey_ = olEvents.listen(this.map, 'click', this.click, this);
  } else {
    this.ngeoToolActivateMgr_.deactivateTool(this.tool);
    unByKey(this.regulargridClickListenerKey_);
  }
};

/**
 * @param {ol.MapBrowserPointerEvent} e An ol map browser pointer event.
 * @private
 */
exports.Controller.prototype.click = function (e) {
  if (this.grid_width % 2 !== 0) {
    alert('La largeur de la grille doit être paire !');
    return;
  }
  if (this.grid_height % 2 !== 0) {
    alert('La hauteur de la grille doit être paire !');
    return;
  }
  this.deleteGrid();

  const square_size = this.square_size;

  const center_x = this.roundNearest(e.coordinate[0], square_size);
  const center_y = this.roundNearest(e.coordinate[1], square_size);
  const point = new olGeomPoint([center_x, center_y]);

  // Center feature
  this.centralFeature.setGeometry(point);
  this.centralFeature.setProperties({
    'text': center_x + ' / ' + center_y,
    'color': '#000',
  });

  if (this.show_center_coords === true) {
    this.source.addFeature(this.centralFeature);
  }

  const ul_x = center_x - (square_size * this.grid_width) / 2;
  const ul_y = center_y + (square_size * this.grid_height) / 2;
  const ur_x = center_x + (square_size * this.grid_width) / 2;
  const ll_y = center_y - (square_size * this.grid_height) / 2;

  const point2 = new olGeomPoint([ul_x, ul_y]);
  // Grid size feature
  this.gridSizeFeature.setGeometry(point2);
  this.gridSizeFeature.setProperties({
    'text': 'Résolution: ' + square_size + ' [m]',
    'color': '#000',
  });

  if (this.show_grid_size === true) {
    this.source.addFeature(this.gridSizeFeature);
  }

  const horz_line_x0 = ul_x;
  const horz_line_x1 = ur_x;
  const horz_line_y = ul_y;

  const vert_line_x = ul_x;
  const vert_line_y0 = ul_y;
  const vert_line_y1 = ll_y;
  let delta_horz_line_y, delta_vert_line_x;
  let label_point_x, label_point_y, label_point;
  let letter = '';
  const features = [];

  for (let i = 0; i <= this.grid_height; i++) {
    delta_horz_line_y = horz_line_y - i * square_size;

    features.push(
      new olFeature({
        geometry: new olGeomLineString([
          [horz_line_x0, delta_horz_line_y],
          [horz_line_x1, delta_horz_line_y],
        ]),
      })
    );

    for (let j = 0; j <= this.grid_width; j++) {
      delta_vert_line_x = vert_line_x + j * square_size;
      // Draw line if first horizontal line
      if (i < 1) {
        features.push(
          new olFeature({
            geometry: new olGeomLineString([
              [delta_vert_line_x, vert_line_y0],
              [delta_vert_line_x, vert_line_y1],
            ]),
          })
        );
      }
      if (i > 0 && j < this.grid_width) {
        letter = this.nextLetter(letter);
        label_point_x = delta_vert_line_x + square_size / 2;
        label_point_y = horz_line_y - i * square_size + square_size / 2;
        label_point = new olFeature({
          geometry: new olGeomPoint([label_point_x, label_point_y]),
          color: '#ff0000',
          text: letter + i,
        });
        features.push(label_point);
      }
    }
    letter = '';
  }
  this.source.addFeatures(features);
};

exports.Controller.prototype.centerCoordinatesVisibility = function () {
  if (this.source.getFeatures().length > 0) {
    if (this.show_center_coords === true) {
      this.source.addFeature(this.centralFeature);
    } else {
      this.source.removeFeature(this.centralFeature);
    }
  }
};

exports.Controller.prototype.gridSizeVisibility = function () {
  if (this.source.getFeatures().length > 0) {
    if (this.show_grid_size === true) {
      this.source.addFeature(this.gridSizeFeature);
    } else {
      this.source.removeFeature(this.gridSizeFeature);
    }
  }
};

exports.Controller.prototype.deleteGrid = function () {
  this.source.clear();
};

exports.Controller.prototype.roundNearest = function (num, acc) {
  if (acc < 0) {
    return Math.round(num * acc) / acc;
  }
  return Math.round(num / acc) * acc;
};

(exports.Controller.prototype.nextLetter = function (id) {
  if (id === '') {
    return 'A';
  }
  const prefix = id.substring(0, id.length - 1);
  const last = id[id.length - 1];
  if (last === 'Z') {
    return this.nextLetter(prefix) + 'A';
  }
  return prefix + String.fromCharCode(id.charCodeAt(id.length - 1) + 1);
}),
  exports.controller('sitjRegulargridController', exports.Controller);

export default exports;
