import angular from 'angular';

import {unByKey} from 'ol/Observable.js';
import olOverlay from 'ol/Overlay.js';
import * as olEvents from 'ol/events.js';
import ngeoToolActivate from 'ngeo/misc/ToolActivate.js';
import ngeoToolActivateMgr from 'ngeo/misc/ToolActivateMgr.js';

const exports = angular.module('adresse', [ngeoToolActivateMgr.name]);

/**
 *
 * @return {angular.Directive} The address directive.
 */
exports.Directive = function () {
  return {
    controller: 'sitjAddressController as ctrl',
    scope: {
      'map': '<sitjAddressMap', // one way Angular binding !
      'active': '=sitjAddressActive',
    }, // all the map values are available (this.map is binded) (it defines how the values are transfered)
    bindToController: true,
  };
};

exports.directive('sitjAddress', exports.Directive);

/**
 * @param {!angular.Scope} $scope Angular scope.
 * @param {!angular.$http} $http Angular http service.
 * @param {!angular.$compile} $compile Angular compile service.
 * @param {!angular.$timeout} $timeout Angular timeout service.
 * @param {ngeo.misc.ToolActivateMgr} ngeoToolActivateMgr Ngeo ToolActivate manager
 *     service.
 * @param {string} sitjAddressUrl URL of the address service.
 * @constructor
 * @private
 * @ngInject
 */
exports.Controller = function ($scope, $http, $compile, $timeout, ngeoToolActivateMgr, sitjAddressUrl) {
  /**
   * @type {angular.Scope}
   * @private
   */
  this.$scope_ = $scope;

  /**
   * @type {angular.$http}
   * @private
   */
  this.$http_ = $http;

  /**
   * @type {angular.$compile}
   * @private
   */
  this.$compile_ = $compile;

  /**
   * @type {angular.$timeout}
   * @private
   */
  this.$timeout_ = $timeout;

  /**
   * @type {ol.Map}
   * @export
   */
  this.map;

  /**
   * @type {boolean}
   */
  this.active;

  /**
   * @type {string}
   * @private
   */
  this.sitjAddressUrl_ = sitjAddressUrl;

  /**
   * @type {ol.EventsKey}
   * @private
   */
  this.addressClickListenerKey_;

  /**
   * The popup content element.
   * @type {Element}
   * @private
   */
  this.content_ = document.createElement('DIV');

  const container = document.createElement('DIV');
  container.classList.add('popover', 'top', 'sitj-addressdata');
  angular.element(container).css('position', 'relative');

  this.content_.setAttribute('sitj-addressdatacontent', '');
  this.content_.classList.add('popover-content');
  container.appendChild(this.content_);
  const arrow = document.createElement('DIV');
  arrow.classList.add('arrow');
  container.appendChild(arrow);

  /**
   * @type {ol.Overlay}
   * @private
   */
  this.overlay_ = new olOverlay({
    element: container,
    stopEvent: true,
    autoPan: true,
    autoPanAnimation: /** @type {olx.animation.PanOptions} */ ({
      duration: 250,
    }),
    positioning: 'bottom-center',
  });

  // Initialize the tools inside of the tool manager
  const tool = new ngeoToolActivate(this, 'active');
  ngeoToolActivateMgr.registerTool('mapTools', tool, false);

  $scope.$watch(
    // First arg = value to watch
    function () {
      return this.active;
    }.bind(this),
    // What should happen:
    // First arg: new value
    // Second arg: old value
    // here it is just going to be true/false
    function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue === true) {
          ngeoToolActivateMgr.activateTool(tool);
          this.addressClickListenerKey_ = olEvents.listen(this.map, 'click', this.click, this);
        } else {
          ngeoToolActivateMgr.deactivateTool(tool);
          unByKey(this.addressClickListenerKey_);
          this.hidePopover();
        }
      }
    }.bind(this)
  );
};

exports.Controller.prototype.showPopover = function () {
  const element = /** @type {Object} */ (this.overlay_.getElement());
  angular.element(element).css('display', 'block');
};

exports.Controller.prototype.hidePopover = function () {
  const element = /** @type {Object} */ (this.overlay_.getElement());
  angular.element(element).css('display', 'none');
};

/**
 * click action should trigger a HTTP request on coordinates and open a popup.
 * @param {ol.MapBrowserPointerEvent} e An ol map browser pointer event.
 * @private
 */
exports.Controller.prototype.click = function (e) {
  const est = e.coordinate[0];
  const nord = e.coordinate[1];

  this.$http_
    .get(this.sitjAddressUrl_ + '/get', {
      params: {
        'lon': est,
        'lat': nord,
      },
    })
    .then(
      function successCallback(response) {
        this.createPopup(response.data, [est, nord]);
      }.bind(this),
      function errorCallback(response) {
        alert('Une erreur est survenue. Merci de contacter le SIT-Jura (sit@jura.ch)');
      }
    );
};

/**
 * @param {Object} data The Geojson response from the address webservice.
 * @param {ol.Coordinate} coordinates The clicked coordinates.
 * @private
 */
exports.Controller.prototype.createPopup = function (data, coordinates) {
  const scope = this.$scope_.$new(true);
  scope['visible'] = false;

  if (data.features.length > 0) {
    const feature = data.features[0];
    coordinates = feature.geometry.coordinates;
    scope['visible'] = true;
    scope['adresse'] = feature.properties['adresse'];
    scope['npa'] = feature.properties['npa'];
    scope['localite'] = feature.properties['localite'];
  }

  this.$compile_(this.content_)(scope);

  this.$timeout_(
    function () {
      this.showPopover();
      this.overlay_.setPosition(coordinates);
    }.bind(this)
  );
};

/**
 * Initialise the controller.
 */
exports.Controller.prototype.$onInit = function () {
  //this.map = this['map'];
  this.map.addOverlay(this.overlay_);
  // Workaround, simulate the first popup display to avoid random map moving that come from a 0 size popup element.
  this.overlay_.setPosition([0, 0]);
  this.showPopover();
  this.hidePopover();
};

exports.controller('sitjAddressController', exports.Controller);

/**
 * @return {angular.Directive} The Directive Definition Object.
 * @ngInject
 */
exports.DatacontentDirective = function () {
  return {
    restrict: 'A',
    scope: true,
    templateUrl: 'sitj/address',
  };
};

exports.run(
  /* @ngInject */ ($templateCache) => {
    $templateCache.put('sitj/address', require('./address.html'));
  }
);

exports.directive('sitjAddressdatacontent', exports.DatacontentDirective);

export default exports;
